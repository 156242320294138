<template>
  <page-layout>
    <template #header>
      <div class="mb-8">
        <k-title>{{ $t('home.pageTitle') }}</k-title>
      </div>
    </template>
    <v-row>
      <v-col>
      </v-col>
    </v-row>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    KTitle,
    PageLayout,
  },
  computed: {
    ...mapGetters('authorisation', {isAdmin: 'isAdmin'}),
  }
};
</script>
